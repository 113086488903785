h4, h5 {
    font-size: 1.5em;
    margin: .25rem;
    padding: .35rem;
}

p {
    font-size: 1.1em;
    margin: .25rem;
    padding: .35rem;
}

.landingPage {
    position: absolute;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    background-color:rgb(50, 58, 66);
}

.accountData {
    font-size: 2vh;
    border-radius: 10px;
    position: relative;
    float: left;
    left: 0px;
    width: 25%;
    min-width: 200px;
    min-height: 300px;
    height: 41%;
    margin: .5% 1% 1% 1%;
    background-color:rgb(95, 158, 160);
    opacity: .7;
    overflow: hidden;
}


.accountNumbers {
    margin: 1% 1% 1% 1%;
    width: 98%;
    height: 41%;
    min-width: 220px;
    float: left;
}

.accountRefresh {
    float: right;
}

.accountPortfolio {
    border-radius: 10px;
    position: relative;
    margin: .5% 1% 1% 1%;
    height: 41%;
    min-height: 300px;
    background-color: rgb(72, 94, 192);
    width: 35%;
    float: left;
    text-align: center;
}

.portfolioNames {
    margin: auto;
    padding-left: 0px;
    left: 0px;
    margin-top: 1%;
    width: 95%;
    height: 90%;
    max-height: 100%;
    overflow-y: scroll;
}

/* width */
.portfolioNames::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
  /* Track */
.portfolioNames::-webkit-scrollbar-track {
    background: #f1f1f100; 
}
/* Handle */
.portfolioNames::-webkit-scrollbar-thumb {
    background: #888; 
}
/* Handle on hover */
.portfolioNames::-webkit-scrollbar-thumb:hover {
    background: #555; 
}
.portfolioTable {
    text-align: left;
}
.portfolioTable th {
    padding: 14px;
}


.barometer {
    border-radius: 10px;
    position: relative;
    margin: .5% 1% 1% 1%;
    display: flex;
    height: 41%;
    min-height: 300px;
    justify-content: center;
    align-items: center;
}


.stockSimulation {
    border-radius: 10px;
    position: relative;
    width: 50%;
    min-width: 200px;
    height: 42%;
    float: left;
    min-height: 300px;
    margin: .5% 1% 1% 1%;
    background-color: rgb(245, 255, 255, .7);
    overflow: hidden;
}

.dataSimulation {
    width: 100%;
    height: 100%;
}

#simulationID {
    width: 100%;
    height: 100%;
}

.simulButton {
    z-index: 2;
    position: absolute;
    left: 5px;
    top: 10px;
    width: 5%;
    color: transparent;
    background-color: transparent;
    border: 0;
    background-image: url('../videos/expandicon2.png');
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center center;
}

.simulButton::after {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.algoControls {
    font-size: 1.5vh;
    border-radius: 10px;
    position: relative;
    width: 15%;
    min-width: 200px;
    height: 42%;
    min-height: 300px;
    margin: .5% 1% 1% 1%;
    float: left;
    background-color: rgb(245, 255, 255, .7);
}

.algoCont {
    width: 100%;
    height: 100%;
    margin: auto;
}

.algoSliders {
    margin: 3% 1% 0 3%;
}

.contentCard {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.4);
    transition: 0.3s;
}

.contentCard:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.4);
}


@media screen and (max-width: 600px) {
    .accountData {
        font-size: 2vh;
        border-radius: 10px;
        position: relative;
        float: left;
        left: 0px;
        width: 95%;
        min-width: 200px;
        height: 40%;
        margin: 3% 2.5% 1% 2.5%;
        background-color:rgb(95, 158, 160);
        opacity: .7;
    }
    .accountPortfolio {
        border-radius: 10px;
        position: relative;
        margin: .5% 1% 1% 1%;
        height: 40%;
        min-height: 300px;
        background-color: rgb(72, 94, 192);
        width: 95%;
        float: left;
        text-align: center;
    }
    .portfolioTableFirstRow {
        height: 5%;

    }
    .barometer {
        border-radius: 10px;
        position: relative;
        width: 95%;
        float: left;
        height: 40%;
        min-width: 200px;
        margin: 1% 2.5% 1% 2.5%;
    } 
    .stockSimulation {
        border-radius: 10px;
        position: relative;
        width: 95%;
        min-width: 200px;
        height: 40%;
        float: left;
        margin: 1% 2.5% 1% 2.5%;
        background-color: rgb(245, 255, 255, .7);
    }

    .algoControls {
        font-size: 1.5vh;
        border-radius: 10px;
        position: relative;
        width: 95%;
        min-width: 200px;
        height: 40%;
        margin: 1% 2.5% 1% 2.5%;
        float: left;
        background-color: rgb(245, 255, 255, .7);
    }
    .algoAccountTransactions {
        font-size: 1.5vh;
        border-radius: 10px;
        position: relative;
        width: 95%;
        min-width: 200px;
        height: 42%;
        margin: 1% 2.5% 1% 2.5%;
        float:left;
        background-color: rgb(245, 255, 255, .7);
    }
}