.menu {
    position: absolute;
    top: 2%;
    right: 2%;
    height: 40%;
    width: 20%;
    background-color: rgb(79, 119, 160);
    border-radius: 20px;
}

.planetMenuExit {
    z-index: 2;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 8%;
    height: 8%;
    color: transparent;
    background-color: transparent;
    border: 0;
    background-image: url('../videos/expandicon2.png');
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center center;
}

.planetTitle {
    position: relative;
    top: 15%;
    height: 10%;
    width: 100%;
    text-align: center;
    font-size: 1.5em;
}

.planetFullName {
    position: relative;
    top: 15%;
    height: 10%;
    width: 100%;
    text-align: center;
    font-size: 1.3em;
}

.planetContent {
    position: relative;
    top: 15%;
    height: 10%;
    width: 100%;
    text-align: center;
    font-size: 1.2em;
}






















.loadingMenu {
    position: relative;
    width: 100%;
    height: 100%;
    display:flex;
    justify-content: center;
    align-items: center;
}

.lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #fff;
    margin: -4px 0 0 -4px;
  }
  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }
  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }
  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }
  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }
  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }
  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }
  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }
  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

