.synapseDiv {
    position: fixed;
}

.topContent {
    position: relative;
    max-height: 90%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.topLeftLogo {
    position: absolute;
    left:0;
    top:0;
}

.centerText {
    position: relative;
    height: auto;
    width: 100%;
    top: 35%;
}

.centerText p {
    font-size: 10vmin;
    text-align: center;
    color: violet;
}

.loginButtons {
    position: relative;
    top:35%;
    align-items: center;
    text-align: center;
}

.topContentButton {
    display: inline-block;
    padding:0.35em 1.2em;
    border:0.1em solid #FFFFFF;
    margin:0 0.3em 0.3em 0;
    border-radius:0.12em;
    box-sizing: border-box;
    text-decoration:none;
    font-family:'Roboto',sans-serif;
    font-weight:300;
    color:violet;
    text-align:center;
    background-color: transparent;
    transition: all 0.2s;
    margin-left: .5%;
    margin-right: .5%;
    font-size: 3vmin;
}

.topContentButton:hover {
    color: #000;
    background-color: gray;
}

.midContent {
    position: relative;
    height: auto;
    width: 100%;
    color: white;
}

.centerWhiteContent {
    left: 0;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: white;
}

.centerGreyContent {
    right: 0;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background-color: grey;
}

.centerGoldContent {
    left: 0;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: gold;
}

.centerBlackContent {
    right: 0;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background-color:beige;
}

.content {
    position: relative;
    width: 100%;
    height: 60vh;
    z-index: 5;
    margin-bottom: 10%;
}

.centerContentChild {
    position: absolute;
    height: 100%;
    width: 70%;
}

.botContent {
    position: relative;
    width: 100%;
    height: 30vh;
    background-color: grey;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 15em;
    z-index: 10;
}

.dontTellAnyone {
    position: relative;
    height: 100vh;
    width: 100%;
    text-align: center;
    color:white;
    font-size: 10em;
}

.dontText {
    position: absolute;
    top: 30%;
    width: 100%;
    text-align: center;
}

.footerCenter {
    height: 50%;
}

.footerCenter .footerImg {
    height: 200px;
    width: auto;

}

.midContent {
    width: 100%;
    height: auto;
}

.largeContent {
    position: relative;
    width: 95vw;
    margin: auto;
    height: 100vh;
}

.content1 {
    position: absolute;
    top: 5%;
    left:5%;
    height: 90%;
    width: 90%;
    background-color: rgba(55, 55, 55, .95); 
    backdrop-filter: blur(5px); 
    border-radius: 20px;
}

.leftContent {
    position: relative;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
}

.rightContent {
    position: relative;
    float: right;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
}

.content2 {
    position: absolute;
    top: 5%;
    left:5%;
    height: 90%;
    width: 90%;
    background-color: rgba(255, 255, 255, .15); 
    backdrop-filter: blur(5px); 
    border-radius: 20px;
}
































.landingPage {
    position: relative;
    width: 100vw;
    height: 100vh;
    scroll-behavior: smooth;
    scroll-margin-top:  20px;
    background-color: white;
}
.topContent {
    width: 100%;
    height: 100%;
    position: relative;
}
.titleHook {
    width: 100%;
    height: 90%;
    position: absolute;
    top: 10%;
    align-items: center;
    z-index: 2;
    text-align: center;
}
.downArrowContainer {
    color:white;
    margin: 0; 
    position: absolute; 
    left: 50%;
    transform: translate(-50%, 0%);
    bottom:3%;
}
.downArrowTop {
    border-right: 5px solid;
    border-bottom: 5px solid;
    height: 20px;
    width: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.bounce {
    animation: bounce 2s infinite;
  }
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: rotate(45deg) translateY(0);
    }
    40% {
      transform: rotate(45deg) translateY(-10px) translateX(-10px);
    }
    60% {
      transform: rotate(45deg) translateY(-5px) translateX(-5px);
    }
  }

.downArrowBottom {
    border-right: 5px solid;
    border-bottom: 5px solid;
    height: 20px;
    width: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.featurePanel {
    background-color: transparent;
    height: 150%;
    width: 100%;
    position: relative;
    z-index: 5;
}

.inverseBorder {
    width: 100%;
    height: 10%;
    position: absolute;
    background-color: transparent;
    z-index: 0;
}
.inverseBorderBottom {
    width: 100%;
    height: 10%;
    bottom: 0;
    position: absolute;
    background-color: transparent;
    z-index: 0;
}

.inverseBorder .cutoutLeft {
    width: 20%;
    height: 200%;
    left: -10%;
    position: absolute;
    background-color: transparent;
    border-radius: 50%;
    z-index: 0;
}
.inverseBorder .cutoutRight {
    width: 20%;
    height: 200%;
    right: -10%;
    position: absolute;
    background-color: transparent;
    border-radius: 50%;
    z-index: 0;
}
.inverseBorder .cutoutLeftBottom {
    width: 20%;
    height: 200%;
    left: -10%;
    bottom: 0;
    position: absolute;
    background-color: transparent;
    border-radius: 50%;
    z-index: 0;
}
.inverseBorder .cutoutRightBottom {
    width: 20%;
    height: 200%;
    right: -10%;
    bottom: 0;
    position: absolute;
    background-color: transparent;
    border-radius: 50%;
    z-index: 0;
}

.centerInfo {
    height: 90%;
    width: 80%;
    min-width: 250px;
    margin: auto;
    margin-top: 0px;
    position: relative;
    background-color: transparent;
    border-radius: 0px 0px 0 0;
}

.whoWeAre {
    color: white;
    padding: 2% 20% 2% 20%;
    justify-content: center;
    text-align: center;
    height: 15%;
    width: 100%;
}

.whoWeAre h3 {
    font-size: 1.8vmax;
}

.features {
    font-weight: bold;
    color:rgb(105,0,173);
    width: 100%;
    height: 80%;
    display:flex;
    flex-wrap: wrap;
    text-align: center;
}

.featCard {
    box-shadow: 4px 4px 8px 4px rgba(105,0,173,.6);
    transition: 0.5s;
    padding: 1% 1% 0% 1%;
    font-size: 1.7vmin;
    position: relative;
    width: 29%;
    height: 40%;
    margin: 2% 2% 0% 2%;
    background-color: rgb(230,230,230);
    border-radius: 15px;
}
.featCard:nth-child(odd) {
    top:5%;
}
.featCard:hover {
    box-shadow: 8px 8px 16px 8px rgba(105,0,173,.6);
    background-color:rgb(45,245,190);
}
.featCardButton {
    color: white;
    background-color: rgb(105,0,173);
    border: none;
    text-align: center;
    border-radius: 10px;
    font-size: 16px;
    width: 80%;
    height: 50px;
    transition: .3s;
}
.featCardButton:hover {
    background-color:rgba(105,0,173,.7)
}
.featCardImg {
    width: 30%;
    height: 30%;
    margin: 2% 0% 5% 0%;
}
.featText {
    min-height: 50%;
    max-height: 50%;
    line-height: 160%;
    width: 100%;
    border-top: 2px solid black;
    padding-top: 5%;
}
.featCard p {
    margin: 0px;
    padding: 0 5% 15% 5%;
}
.cardButtonDiv {
    height: 15%;
    width: 100%;
}
.recAlgoSection {
    position: relative;
    top: -1px;
    width: 100%;
    height: 50%;
    background-color: black;
}
.recSimulSection {
    position: relative;
    width: 100%;
    top: -1px;
    height: 50%;
    background-color: black;
    margin-bottom: 5%;
}
.infoContainerBreakdown {
    width: 100%;
    height: 50%;
    background-color: white;
}
.GraphicSection {
    width: 40%;
    height: 100%;
}
.InfoSection {
    width: 60%;
    height: 100%;
}
.InfoSection ul {
    width: 70%;
    margin: auto;
    padding: 0;
}
.InfoSection ul ul{
    width: 70%;
    margin: auto;
    padding: 0;
}
.InfoSection h5 {
    margin: 3% 0% 2% 5%;
    text-align: center;
    font-size: 1.5vmax;
    text-align: left;
}
.InfoSection li {
    list-style-type: none;
    margin: 2% 0% 3% 0%;
    padding: 0;
    font-size: 1vmax;
}

.Universe {
    z-index: 10;
}

.simulGraphic {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    text-align: center;
}

.upfrontText {
    position: relative;
    z-index: 10;
    margin: 0% 0% 1% 20%;
}
.algoContainer {
    font-weight: bold;
    height: 90%;
    width: 95%;
    margin: auto;
    position: relative;
    background-color:rgb(125, 237, 255);
    border-radius: 50px;
    display: flex;
}
.simulationContainer {
    height: 90%;
    width: 95%;
    margin: auto;
    position: relative;
    background-color: rgba(149,0,255,.4);
    border-radius: 50px;
    display: flex;
}
.controlAndGainContainer {
    height: 90%;
    width: 95%;
    margin: auto;
    position: relative;
    display: flex;
}
.halfInfoSection {
    width: 49%;
    height: 100%;
    background-color: rgb(45,245,190);
    border-radius: 50px;
}
.halfInfoSection:nth-child(even) {
    margin-left: 2%;
}
.halfInfoSection h5 {
    margin: 3% 0% 2% 5%;
    text-align: center;
    font-size: 1.5vmax;
    text-align: left;
}
.halfInfoSection li {
    list-style-type: none;
    margin: 1% 0% 2% 0%;
    font-size: 1vmax;
}
.halfInfoSection ul {
    width: 70%;
    margin: auto;
    padding: 0;
}
.brokerageContainer {
    font-weight: bold;
    height: 90%;
    width: 95%;
    margin: auto;
    position: relative;
    background-color: rgba(149,0,255,.9);
    border-radius: 50px;
    display: flex;
}

.brokerageGraphicSection {
    width: 40%;
    height: 100%;
}
.footer {
    background-color: black;
    position: relative;
    bottom: 0;
    width: 100%;
    height: 30%;
    display: flex;
    flex-wrap: wrap;
}

.ULText {
    font-size: 20px;
    color:white;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.ULText li {
    width: 100%;
}

.ULText li:hover {
    color: rgb(105,0,173);
    cursor: pointer;
}

.footerImage {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33%;
    height: 100%;
}

.footerImg {
    width: 200px;
    height: 100px;
}

.leftUL {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    width: 33%;
    height: 100%;
    margin: auto;
    text-align: center;
}

.rightUL {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    width: 33%;
    height: 100%;
    margin: auto;
    text-align: center;
}


/* Hide scrollbar for Chrome, Safari and Opera 
.landingPage::-webkit-scrollbar {
    display: none;
  } */
  
  /* Hide scrollbar for IE, Edge and Firefox 
  .landingPage {
    -ms-overflow-style: none;  
    scrollbar-width: none;  
  }*/

@media screen and (max-width: 900px) {
    .inverseBorder {
        opacity: 0;
    }
    .easyHide {
        opacity: 0;
    }
    .featurePanel {
        margin-top: 15%;
        height: 100%;
    }
    .centerInfo {
        width: 95%;
        height: 95%;
        top: -7%;
        border-radius: 50px;
    }
    .algoContainer {
        margin: auto;
        width: 95%;
    }
    .simulationContainer {
        margin: auto;
        width: 95%;
    }
    .controlAndGainContainer {
        margin: auto;
        width: 95%;
    }
    .brokerageContainer {
        margin: auto;
        width: 95%;
    }
    .featCardButton {width: 75px;height:30px;font-size: 12px;}
    .ULText {
        font-size: 12px;
    }
    .InfoSection li {
        font-size: 15px;;
    }

}

@media all and (max-width: 600px) {
    .featurePanel {
        height: auto;
        margin-bottom: 10%;
    }
    .centerInfo {height: auto;}
    .featCard {
        width: 40%;
        margin: auto;
        margin-bottom: 10%;
        margin-top: 0px;
        height: auto;
        padding-bottom: 3%;
        top:0;
    }
    .featCard {font-size: 9px;}
    .InfoSection h5 {font-size: 15px;padding-left: 5%;}
    .InfoSection li {font-size: 10px;}
    .footerImg { width: 120px; height: 60px;}

}

@media all and (max-width: 300px) {
    .featurePanel {
        height: auto;
        margin-bottom: 10%;
    }
    .centerInfo {
        top: 10%;
        height: auto;
    }
    .featCard {
        min-height: 98%;
        width: 90%;
        margin: auto;
        margin-bottom: 7%;
        margin-top: 0px;
        padding-bottom: 6%;
    }
}
